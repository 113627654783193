<template>
  <v-dialog persistent fullscreen :value="value" transition="slide-x-reverse-transition">
    <v-card tile>

      <!-- Header -->
      <v-toolbar color="primary" flat dark>
        <v-btn icon @click="onClose">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- title -->
        <v-toolbar-title> Busca Asistentes </v-toolbar-title>
        <!-- actins -->
        <v-btn disabled icon />
      </v-toolbar>

      <v-card-text>
        <v-text-field class="mt-5" v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
          outlined dense />
      </v-card-text>
      <v-divider></v-divider>

      <!-- Table -->
      <v-data-table :headers="headers" hide-default-header :search="search" :items="attendees" :items-per-page="50">
        <template v-slot:body="{ items }">
          <tbody v-if="items.length > 0">
            <tr v-for="(item, index) in items" :key="item.uid">
              <v-divider v-if="index > 0" />
              <v-list-item two-line @click="filter(item)">
                <v-list-item-content>
                  <v-list-item-title class="text-capitalize | font-weight-medium">
                    {{ item.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>{{ item.rut }}</v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-chip :color="item.activeTicket ? 'success' : 'error'" outlined small>
                    <span v-if="!item.checkIn" class="font-weight-bold">
                      {{ item.activeTicket ? "Activo" : "Desactivado" }}
                    </span>
                    <span v-else class="font-weight-bold">
                      {{ item.checkIn.state ? "Ingresado" : "Rechazado" }}
                    </span>
                  </v-chip>
                </v-list-item-action>
              </v-list-item>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <div class="pa-3 text-center">Asistente no esta en lista</div>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>

    <!-- Validator -->
    <validator v-model="valid" v-if="valid" :result="validResult" style="z-index: 10" @close="onClose" />

    <!-- Validator Loading -->
    <v-overlay :value="validLoading">
      <v-progress-circular indeterminate color="accent" width="2" size="80" />
    </v-overlay>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Validator from "./Validation.vue"

export default {
  props: ['value'],

  components: { Validator },

  data: () => ({
    search: '',
    headers: [
      { text: "Asistente", value: "name" },
      { text: "Rut", value: "rut" },
    ],

    valid: false,
    validResult: {},
    validLoading: false,
  }),

  computed: {
    ...mapState("attendees", ["attendees"])
  },

  methods: {
    ...mapActions("attendees", ["scanFindAttendee"]),

    onClose() {
      this.$emit('input', false);
      this.search = '';
      this.valid = false;
      this.validResult = {};
      this.validLoading = false;
    },

    async filter(item) {
      this.validLoading = true;
      await this.scanFindAttendee({ id: item.rut, event: item.eventId }).then(
        (resp) => {
          this.valid = true;
          this.validResult = resp;
        }
      );
      this.validLoading = false;
    }
  },
}
</script>