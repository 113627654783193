<template>
  <div class="components-reader">

    <v-row>
      <v-col cols="6">
        <v-btn block x-large depressed color="primary" @click="dialog = true">
          Escánear Asistente
        </v-btn>
      </v-col>
      <v-col cols="6">
        <v-btn block x-large depressed color="accent"  @click="search = true">
          Buscar por Rut o Nombre
        </v-btn>
      </v-col>
    </v-row>

    
    <dialog-search v-model="search" />

    <!-- dialog: ready -->
    <v-dialog v-model="dialog" persistent max-width="290">
      <v-card>
        <v-card-title class="justify-center">
          {{ loading ? "Validando Ticket" : "Listo para Escánear" }}
        </v-card-title>

        <v-card-text class="text-center">
          <p>Presiona el botón del escáner hasta detectar el QR</p>
          <v-text-field @change="onScan" ref="focusMe" autofocus class="input-none" v-model="item" />

          <v-icon v-if="!loading" size="50">mdi-qrcode-scan</v-icon>
          <v-progress-circular v-else indeterminate color="success" size="50" />

          <v-btn large block outlined color="grey" class="rounded-xl mt-6" @click="onCancel" :disabled="loading">
            Cancelar
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import _ from "lodash";
import { mapActions } from "vuex";
import DialogSearch from '@/components/scan/Finder.vue'

import { db } from "@/firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

let unsubscribe;

export default {
  components: { DialogSearch },

  data: () => ({
    item: "",
    search: false,
    dialog: false,
    loading: false,
  }),

  watch: {
    item() {
      this.item ? (this.loading = true) : (this.loading = false);
    },
  },

  methods: {
    ...mapActions("attendees", ["scanFindAttendee"]),

    onCancel() {
      this.dialog = false;
      this.$refs.focusMe.reset();
    },

    isValidUrl(str) {
      try {
        const url = new URL(str);
        return url.protocol === "https:";
      } catch (err) {
        return false;
      }
    },

    async onScan(e) {
      const str = e.replace(/ /g, "");

      if (this.isValidUrl(str)) {
        const id = new URL(str).searchParams.get("RUN");
        const eventId = this.$route.params.uid;
        await this.scanFindAttendee({ id: id, event: eventId }).then((resp) => {
          this.$emit("result", resp);
        });

        this.loading = false;
        this.onCancel();
      } else {
        this.loading = false;
        this.onCancel();
      }
    },

    async readerAttendees() {
      const q = query(
        collection(db, `companies/${this.user.companyId}/junction`),
        where("eventId", "==", this.$route.params.uid)
      );

      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const items = [];
        querySnapshot.forEach((doc) => {
          items.push(_.merge({ event: this.$route.params.uid }, doc.data()));
        });
        this.$store.commit("attendees/setAttendees", items);
      });
    },
  },

  beforeDestroy() {
    unsubscribe();
  },
};
</script>

<style lang="scss" scoped>
.input-none {
  opacity: 0;
  position: absolute;
}
</style>